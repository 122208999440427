var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useMemo } from 'react';
import styled from '@emotion/styled';
import { Body, Colors } from '@robinpowered/design-system';
import { useHeaderContext } from '../../contexts';
import { useActiveOrganization, useFeatureFlag } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { useGainsight } from '../../hooks';
import { FeatureFlagType } from '../../generated';
// Match /org-slug/setup at the start of the pathname
// Allow for the optional trailing slash or no trailing slash.
var MATCH_SETUP_TAB = /^\/[^/]*?\/setu(p$|p\/)/;
var HeaderMenuWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  margin-right: auto;\n  margin-left: 10px;\n\n  // Have the header 'break' instead of the entire app at\n  // smaller screen sizes until we have an actual responsive header\n  width: 100%;\n  overflow-x: auto;\n"], ["\n  align-items: center;\n  display: flex;\n  margin-right: auto;\n  margin-left: 10px;\n\n  // Have the header 'break' instead of the entire app at\n  // smaller screen sizes until we have an actual responsive header\n  width: 100%;\n  overflow-x: auto;\n"])));
var MenuLink = styled.a(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-items: center;\n  color: ", ";\n  display: flex;\n  font-size: 0.875rem;\n  font-weight: 500;\n  justify-content: center;\n  border: 2px solid transparent;\n  border-radius: 12px;\n  padding: 6px 8px;\n  margin: 0;\n  text-decoration: none;\n  white-space: pre;\n\n  &:hover {\n    color: ", ";\n  }\n\n  &:focus-visible {\n    border-color: ", ";\n  }\n"], ["\n  align-items: center;\n  color: ", ";\n  display: flex;\n  font-size: 0.875rem;\n  font-weight: 500;\n  justify-content: center;\n  border: 2px solid transparent;\n  border-radius: 12px;\n  padding: 6px 8px;\n  margin: 0;\n  text-decoration: none;\n  white-space: pre;\n\n  &:hover {\n    color: ", ";\n  }\n\n  &:focus-visible {\n    border-color: ", ";\n  }\n"])), function (props) { return (props.isActive ? Colors.Maroon100 : Colors.Gray80); }, Colors.Maroon100, Colors.Maroon100);
export var HeaderMenu = function () {
    var _a, _b, _c, _d, _e;
    var t = useTranslation('headerMenu').t;
    var _f = useHeaderContext(), activeOrg = _f.activeOrg, userPermissions = _f.userPermissions, isLoading = _f.isLoading;
    // For performance reasons, it's quicker to get a single org than all orgs from getCurrentUserAndOrgs
    var currentOrgData = useActiveOrganization().data;
    var orgSlug = activeOrg === null || activeOrg === void 0 ? void 0 : activeOrg.slug;
    var canListUsers = userPermissions.canListUsers, canManageAmenities = userPermissions.canManageAmenities, canViewAnalytics = userPermissions.canViewAnalytics, canViewBilling = userPermissions.canViewBilling, canViewCheckpoints = userPermissions.canViewCheckpoints, canViewDevices = userPermissions.canViewDevices, canViewOfficeAccess = userPermissions.canViewOfficeAccess, canManageGroups = userPermissions.canManageGroups, canManagePlaces = userPermissions.canManagePlaces, canManageRoles = userPermissions.canManageRoles, canManageUsers = userPermissions.canManageUsers, canViewVisitTab = userPermissions.canViewVisitTab, canManageWorkplaceServices = userPermissions.canManageWorkplaceServices, canViewWorkweek = userPermissions.canViewWorkweek, canManageWorkPolicies = userPermissions.canManageWorkPolicies, arePersonalCalendarsEnabled = userPermissions.arePersonalCalendarsEnabled;
    // @TODO we should also be checking if the user has an admin role,
    // but since an admin is likely to have at least one of these
    // permissions, this will cover most cases, for now.
    var showManageTab = canManageAmenities ||
        canViewBilling ||
        canViewCheckpoints ||
        canViewDevices ||
        canViewOfficeAccess ||
        canManageGroups ||
        canManagePlaces ||
        canManageRoles ||
        canManageUsers ||
        canManageWorkplaceServices ||
        canManageWorkPolicies;
    var showScheduleTab = arePersonalCalendarsEnabled;
    var showPeopleTab = ((_a = currentOrgData === null || currentOrgData === void 0 ? void 0 : currentOrgData.getOrganizationById) === null || _a === void 0 ? void 0 : _a.hasActiveLocations) &&
        canListUsers &&
        ((_b = activeOrg === null || activeOrg === void 0 ? void 0 : activeOrg.features) === null || _b === void 0 ? void 0 : _b.peopleDirectory);
    var showInsightsTab = ((_c = currentOrgData === null || currentOrgData === void 0 ? void 0 : currentOrgData.getOrganizationById) === null || _c === void 0 ? void 0 : _c.hasActiveLocations) && canViewAnalytics;
    var showVisitsTab = canViewVisitTab;
    var showWorkWeek = canViewWorkweek && ((_d = activeOrg === null || activeOrg === void 0 ? void 0 : activeOrg.features) === null || _d === void 0 ? void 0 : _d.workweek);
    var settingsPageDefault = canManagePlaces
        ? 'offices'
        : canViewOfficeAccess
            ? 'access'
            : canViewCheckpoints
                ? 'integrations'
                : canViewBilling
                    ? 'billing'
                    : canViewDevices
                        ? 'devices'
                        : canManageUsers
                            ? 'users'
                            : canManageGroups
                                ? 'groups'
                                : canManageRoles
                                    ? 'roles'
                                    : canManageWorkplaceServices
                                        ? 'services'
                                        : canManageAmenities
                                            ? 'amenities'
                                            : canManageWorkPolicies
                                                ? 'work-policies'
                                                : 'customization';
    var isMapUploadOnboardingComplete = useMemo(function () {
        var currentOrg = currentOrgData === null || currentOrgData === void 0 ? void 0 : currentOrgData.getOrganizationById;
        if (!currentOrg) {
            return true; // Default value while loading: don't show onboarding.
        }
        return !currentOrg.mapOnboardingStatus.shouldShowSetup;
    }, [currentOrgData === null || currentOrgData === void 0 ? void 0 : currentOrgData.getOrganizationById]);
    var isFinishSetupFocused = MATCH_SETUP_TAB.test(window.location.pathname);
    var finishSetupLink = useMemo(function () {
        var _a, _b;
        if (isMapUploadOnboardingComplete ||
            !(currentOrgData === null || currentOrgData === void 0 ? void 0 : currentOrgData.getOrganizationById) ||
            !((_b = (_a = currentOrgData === null || currentOrgData === void 0 ? void 0 : currentOrgData.getOrganizationById) === null || _a === void 0 ? void 0 : _a.mapOnboardingStatus.location) === null || _b === void 0 ? void 0 : _b.id)) {
            return null;
        }
        var _c = currentOrgData.getOrganizationById.mapOnboardingStatus, level = _c.level, location = _c.location;
        return (level === null || level === void 0 ? void 0 : level.id)
            ? "/".concat(orgSlug, "/setup/upload-map?locationId=").concat(location === null || location === void 0 ? void 0 : location.id, "&levelId=").concat(level === null || level === void 0 ? void 0 : level.id)
            : "/".concat(orgSlug, "/setup/add-floor?locationId=").concat(location === null || location === void 0 ? void 0 : location.id);
    }, [
        isMapUploadOnboardingComplete,
        currentOrgData === null || currentOrgData === void 0 ? void 0 : currentOrgData.getOrganizationById,
        orgSlug,
    ]);
    var showFinishSetupTab = finishSetupLink &&
        ((_e = activeOrg === null || activeOrg === void 0 ? void 0 : activeOrg.features) === null || _e === void 0 ? void 0 : _e.maps) &&
        (isFinishSetupFocused ||
            (!isMapUploadOnboardingComplete && canManagePlaces));
    var workPlaceServiceFF = useFeatureFlag(FeatureFlagType.WorkplaceServices).data;
    var deliveriesFF = useFeatureFlag(FeatureFlagType.DeliveriesTab).data;
    var showWorkplaceServicesTab = (workPlaceServiceFF === null || workPlaceServiceFF === void 0 ? void 0 : workPlaceServiceFF.isFeatureFlagEnabledForOrgOrMe) &&
        canManageWorkplaceServices;
    var showDeliveriesTab = deliveriesFF === null || deliveriesFF === void 0 ? void 0 : deliveriesFF.isFeatureFlagEnabledForOrgOrMe;
    // Gainsight trial tracking script
    useGainsight();
    return (_jsx(HeaderMenuWrapper, { children: !isLoading && (_jsxs(Fragment, { children: [showFinishSetupTab && (_jsxs(_Fragment, { children: [_jsx(MenuLink, __assign({ href: isFinishSetupFocused ? window.location.href : finishSetupLink, isActive: isFinishSetupFocused }, { children: t('setup') })), _jsx(RedDot, {})] })), showScheduleTab && (_jsx(MenuLink, __assign({ href: "/".concat(orgSlug, "/schedule/").concat(showWorkWeek ? 'week' : 'user'), isActive: window.location.pathname.includes('/schedule') }, { children: t('schedule') }))), _jsx(MenuLink, __assign({ href: "/".concat(orgSlug, "/office") }, { children: t('office') })), showPeopleTab && (_jsx(MenuLink, __assign({ href: "/".concat(orgSlug, "/people/"), isActive: window.location.pathname.includes('/people') }, { children: t('people') }))), showInsightsTab && (_jsx(MenuLink, __assign({ href: "/".concat(orgSlug, "/insights"), isActive: window.location.pathname.includes('/insights') }, { children: t('analytics') }))), showVisitsTab && (_jsx(MenuLink, __assign({ href: "/".concat(orgSlug, "/visits/"), isActive: window.location.pathname.includes('/visits') }, { children: t('visits') }))), showDeliveriesTab && (_jsx(MenuLink, __assign({ href: "/".concat(orgSlug, "/deliveries/"), isActive: window.location.pathname.includes('/deliveries') }, { children: t('deliveries') }))), showWorkplaceServicesTab && (_jsx(_Fragment, { children: _jsxs(MenuLink, __assign({ href: "/".concat(orgSlug, "/tickets/") }, { children: [t('services'), ' ', _jsx(BetaTag, { children: _jsx(Body.XSmall, { children: t('beta') }) })] })) })), showManageTab && (_jsx(_Fragment, { children: _jsx(MenuLink, __assign({ href: "/".concat(orgSlug, "/settings/").concat(settingsPageDefault) }, { children: t('manage') })) }))] })) }));
};
var BetaTag = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 1px 8px;\n  background: ", ";\n  border-radius: 100px;\n  color: ", ";\n"], ["\n  padding: 1px 8px;\n  background: ", ";\n  border-radius: 100px;\n  color: ", ";\n"])), Colors.Magenta100, Colors.White0);
var RedDot = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin: -8px 10px 0 -7px;\n  height: 7px;\n  width: 7px;\n  border-radius: 50%;\n  background: ", ";\n  flex-shrink: 0; /* Don't allow it to get squished */\n"], ["\n  margin: -8px 10px 0 -7px;\n  height: 7px;\n  width: 7px;\n  border-radius: 50%;\n  background: ", ";\n  flex-shrink: 0; /* Don't allow it to get squished */\n"])), Colors.RedProduct);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
